import React from "react";
import "./Dashboard.scss";

function Dashboard() {
  return (
    <div className="dashboard">
      <div className="dashboard-container">
        <h1>Dashboard</h1>
      </div>
    </div>
  );
}

export default Dashboard;
