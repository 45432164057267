import React from 'react';
import "./About.scss"

function About() {
  return (
    <div className='dashboard'>
      <div className="dashboard-container">
        <h1>About</h1>
      </div>
    </div>
  )
}

export default About
