import React from 'react'

const WorkDone = () => {
  return (
    <div className="dashboard">
      <div className="dashboard-container">
        <h1>Workdone</h1>
      </div>
    </div>
  )
}

export default WorkDone
