import React from 'react'


const Services = () => {
  return (
    <div className="dashboard">

      <div className="dashboard-container">
        <h1>Services
        </h1>
      </div>
    </div>
  )
}

export default Services
