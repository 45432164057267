import React from 'react'


const Messages = () => {
  return (
    <div className="dashboard">
      <div className="dashboard-container">
        <h1>Messages</h1>
      </div>
    </div>
  )
}

export default Messages
