import React from 'react'

const Footer = () => {
  return (
    <div className="dashboard">
      <div className="dashboard-container">
        <h1>Footer</h1>
      </div>
    </div>
  )
}

export default Footer
