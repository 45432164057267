import React from 'react'

const UserMail = () => {
  return (
    <div className="dashboard">
      <div className="dashboard-container">
        <h1>UserMail</h1>
      </div>
    </div>
  )
}

export default UserMail
