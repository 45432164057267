import React from 'react';
import "./Home.scss";

function Home() {
  return (
    <div className='dashboard'>
      <div className="dashboard-container">
        <h1>Home</h1>
      </div>
    </div>
  )
}

export default Home
