import React from 'react'


const Portfolio = () => {
  return (
    <div className="dashboard">
      <div className="dashboard-container">
        <h1>Portfolio</h1>
      </div>
    </div>
  )
}

export default Portfolio
